import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Hub } from "aws-amplify/utils";
import { AuthUser, getCurrentUser, signOut } from "aws-amplify/auth";
import { Subject, Observable, BehaviorSubject, } from 'rxjs';
import { Entities, setConfig, ContrailConfig, getConfig } from '@contrail/sdk';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { AuthActions } from './auth-store';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

let DEFAULT_REDIRECT_PAGE;
if (environment.production) {
  DEFAULT_REDIRECT_PAGE = "https://hub.vibeiq.com"
} else if (environment.name === "STAGING") {
  DEFAULT_REDIRECT_PAGE = "https://hub-staging.vibeiq.com"
} else if (environment.name === "DEVELOPMENT") {
  DEFAULT_REDIRECT_PAGE = "https://hub.dev.vibeiq.com"
} else if (environment.name === "STAGING") {
  DEFAULT_REDIRECT_PAGE = "https://hub-staging.vibeiq.com"
} else if (["PRODUCTION_LOCAL", "DEVELOPER-ENV", "DEVELOPMENT-LOCAL", "PRODUCTION-LOCAL", "DEFAULT - LOCAL"].includes(environment.name)) {
  DEFAULT_REDIRECT_PAGE = "http://localhost:4205"
} else if (environment.name === 'FEATURE_BRANCH') {
  console.log("Triggering auto detection of hostname for feature branch");
  console.log(`computed hostname: ${window.location.protocol}:${window.location.hostname}`)
  DEFAULT_REDIRECT_PAGE = `${window.location.protocol}:${window.location.hostname}`;
} else {
  throw new Error(`Environment is invalid. Login URL can not be set ${environment.name}`)
}

function getApiGateway() {
  return getConfig().apiGateway;
}
export interface AuthContext {
  user?: any;
  currentOrg?: any;
  token?: string;
  sharedLink?: any;
}
export interface User {
  email?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  profilePhoto?: string;
  orgs?: Array<OrgMembership>;
}

export interface OrgMembership {
  orgSlug: string;
  role: string;
  orgId: string;
}


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  private user: User;
  private currentOrg: OrgMembership;
  private userPromise;
  private shareToken;

  private authContextSubject: Subject<AuthContext> = new BehaviorSubject(null);
  public authContext: Observable<AuthContext> = this.authContextSubject.asObservable();
  private authContextObject: AuthContext = {};

  private returnUrl = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private store: Store<RootStoreState.State>,
    private cookieService: CookieService,
  ) {
    Hub.listen('auth', this.authStateListener);
    const service = this;
    setConfig({
      authTokenResolver: {
        resolveAuthToken() {
          return service.resolveJWTToken();
        },
      }
    });
    setConfig({
      apiGateway: environment.apiBaseUrl,
    });
  }

  public async setShareToken(shareToken: string) {
    if (!shareToken) {
      return;
    }
    this.shareToken = shareToken;
  }

  public async loadShareLink() {

    if (!this.shareToken) {
      return;
    }
    const sharedLink = await new Entities().get({ entityName: 'shared-link', id: this.shareToken });
    // this.authContextObject.sharedLink = sharedLink;
    this.store.dispatch(AuthActions.setSharedLink({ sharedLink }));
  }

  public async getAuthContext(): Promise<AuthContext> {
    this.authContextObject = {
      ... this.authContextObject,
      token: await this.getToken(),
    };
    return Promise.resolve(this.authContextObject);
  }

  public async getToken(): Promise<string> {
    return this.resolveJWTToken();
  }

  public async loadToken(): Promise<string> {
    this.token = await this.resolveJWTToken();
    setConfig({
      apiUserToken: this.token,
      apiGateway: environment.apiBaseUrl,
    });
    return this.token;
  }

  private async resolveJWTToken(): Promise<string> {
    let token = this.cookieService.get('vibeAccessToken');
    if (!Boolean(token)) {
      console.log('JWT token is not set, logging out.');
      return;
    }

    // ensure token is not expired
    let tokenObject: any;

    // if the token is not valid attempt a refresh
    try {
      tokenObject = jwt_decode(token);
    } catch (decodeError) {
      console.error("Could not decode JWT token. Attempting a refresh...");
      try {
        token = await this.refreshAccessToken();
        tokenObject = jwt_decode(token);
      } catch (refreshError) {
        console.error("Could not refresh jwt token. Printing error and Logging out...");
        console.error(refreshError);
        console.error(decodeError);
        await this.logout();
      }
      // if we reach this, we refreshed the token correctly.
    }

    // if the token is valid but expired
    if (this.isTokenExpired(tokenObject)) {
      console.log("access token expired... attempting a refresh");
      token = await this.refreshAccessToken();
      if (!token) {
        console.log("refresh failed. Logging out...");
        await this.logout();
      }
    }

    if (this.shareToken) {
      token += ':' + this.shareToken;
    }
    return token;
  }

  public async getCurrentUser(): Promise<User> {
    if (this.user) {
      return Promise.resolve(this.user);
    } else {
      if (!this.userPromise) {
        this.userPromise = this.loadCurrentUser();
      } else {
      }
      return this.userPromise;
    }
  }

  /**
   * refresh the user data
   */
  public refreshUser() {
    this.loadCurrentUser();
  }
  private async loadCurrentUser(): Promise<User> {
    this.user = await this.http.get(environment.apiBaseUrl + '/users/current').toPromise() as User;
    this.user.orgs = this.user.orgs.filter(o => o.orgSlug);
    this.user.orgs.sort((o1, o2) => o1.orgSlug > o2.orgSlug ? 1 : -1);
    this.authContextObject.user = this.user;
    // this.store.dispatch(AuthActions.setAuthContext({ authContext: ObjectUtil.mergeDeep({}, this.authContextObject) }));
    return Promise.resolve(this.user);
  }

  /** Currently only called from UserResolver. */
  public setCurrentOrg(org: any): void {
    console.log('setCurrentOrg: ', org);
    localStorage.setItem('lastOrgSlug', org?.orgSlug);
    this.currentOrg = org;
    this.authContextObject = {
      currentOrg: org,
      user: this.user,
    };
    const orgSlug = org.orgSlug;
    setConfig({ orgSlug });
    this.authContextSubject.next(this.authContextObject);
    this.store.dispatch(AuthActions.setAuthContext({ authContext: this.authContextObject }));

  }
  public getCurrentOrg(): any {
    return this.currentOrg;
  }
  public getRole() {
    return this.currentOrg.role;
  }
  public isAdmin() {
    return this.getRole() === 'ADMIN';
  }
  public async logout() {
    await this.clearCognitoData();
    console.log("deleting cookies for domain: ", environment.domain);
    this.cookieService.delete('vibeAccessToken', '/', environment.domain);
    this.cookieService.delete('vibeRefreshToken', '/', environment.domain);
    this.cookieService.delete('vibeAccessToken', '/', '.vibeiq.com');
    this.cookieService.delete('vibeRefreshToken', '/', '.vibeiq.com');
    this.cookieService.delete('vibeAccessToken', '/', '.dev.vibeiq.com');
    this.cookieService.delete('vibeRefreshToken', '/', '.dev.vibeiq.com');

    this.token = null;
    this.user = null;
    this.currentOrg = null;
    this.authContextObject = {};
    this.router.navigate(['/login']);
  }

  public async clearCognitoData() {
    console.log("clearing cognito data!")
    try {
      const user: AuthUser = await getCurrentUser();
      console.log("user:", user)
      if (user) {
        console.log("signing out user:", user)
        await signOut();
      }
    } catch (err) {
      console.error('error logging out: ', err);
    }
  }
  authStateListener = async (data) => {
    switch (data.payload.event) {
      case 'signedIn':
        console.info('Auth: user signed in');
        const idToken = (await cognitoUserPoolsTokenProvider.getTokens()).idToken.toString();
        await this.authenticateUser(idToken);
        this.returnUrl = this.route?.snapshot?.queryParams?.returnUrl || '/';
        console.log("return url after login:", this.returnUrl)
        document.location.href = this.returnUrl;
        break;
      case 'signedUp':
        console.info('user signed up');
        break;
      case 'signedOut':
        console.info('user signed out');
        break;
      default:
        console.info('authStateListener >>>', data.payload.event)
        break;
    }
  }

  public isTokenExpired(tokenObject: any) {
    return (tokenObject.exp && (Date.now() > tokenObject.exp * 1000))
  }

  public isSignedIn(): boolean {
    const cookiesToCheck = ['vibeAccessToken', 'vibeRefreshToken']
    for(const cookieName of cookiesToCheck) {
      try {
        const theToken = this.cookieService.get(cookieName);
        if(!theToken) {
          console.log(`isSignedIn: no ${cookieName} token`)
          return false;
        }
        if(this.isTokenExpired(jwt_decode(theToken))) {
          console.log(`isSignedIn: ${cookieName} expired`)
          return false;
        }
      } catch (e) {
        console.log("isSignedIn: error while decoding jwt");
        console.error(e);
        return false;
      }
    }
    return true;
  }

  public getDefaultHomePage(): string {
    return DEFAULT_REDIRECT_PAGE
  }

  requestRefreshedAccessToken() {
    const url = getApiGateway() + '/auth/jwt_refresh/';
    return this.http.post(url, `{
      "refreshToken": "${this.cookieService.get('vibeRefreshToken')}"
     }`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }



  public async setAccessToken(accessToken: string): Promise<string> {
    const accessTokenObject = jwt_decode(accessToken) as any;
    const domain = environment.domain;
    this.cookieService.set(
      'vibeAccessToken',
      accessToken,
      {
        expires: new Date(accessTokenObject.exp * 1000),
        path: '/',
        domain,
      }
    );
    setConfig({
      apiUserToken: accessToken,
    });
    console.log(`setting cookie (${accessToken}) vibeAccessToken to ${domain}`)
    return accessToken
  }


  public async setRefreshToken(refreshToken): Promise<void> {
    const refreshTokenExpiration = new Date((jwt_decode(refreshToken) as any).exp * 1000);
    const domain = environment.domain;
    this.cookieService.set(
      'vibeRefreshToken',
      refreshToken,
      {
        expires: refreshTokenExpiration,
        path: '/',
        domain,
      }
    );
    console.log(`setting cookie (${refreshToken}) vibeRefreshToken to ${domain}`)
  }

  getSSORequest(email: string) {
    const domain = email.substring(email.indexOf("@") + 1);
    const link = environment.apiBaseUrl + '/sso-login/domain-check/' + domain;
    console.log(link);
    return this.http.get(link);
  }

  async refreshAccessToken(): Promise<string> {
    try {
      const response = await this.requestRefreshedAccessToken().toPromise() as any;
      return await this.setAccessToken(response.accessToken);
    } catch (e) {
      console.log("failed to refresh the user's access token. Logging out...")
      console.error(e);
    }
    return null;
  }

  async authenticateUser(cognitoAccessToken) {
    const config: ContrailConfig = getConfig();

    console.log("authenticateUser: ", config.apiGateway)
    const url = config.apiGateway + '/auth/cognito/';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: `{
       "accessToken": "${cognitoAccessToken}"
      }`,
    });

    return await response.json().then(data => {
      console.log("token response: ", data)
      console.log("authenticateUser: setting tokens ", environment.domain)
      this.setAccessToken(data.accessToken);
      this.setRefreshToken(data.refreshToken);
      return data.accessToken;
    });
  };
}