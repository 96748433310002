<div class="login-container">
  <ng-container *ngIf="!showCognitoLogin; else awsLogin">
    <div class="email-form-section">
      <div class="wrapper">
        <img src="assets/vibeiq_logo.jpg" class="logo">
        <div class="title">Welcome to Vibe!</div>

        <form #f="ngForm" (ngSubmit)="onEmailSubmit(f)">
          <input class="input-email" type="email" name="email" ngModel required #email="ngModel" placeholder="Enter your email address">
          <button type="submit" class="btn-signin">SIGN IN</button>
        </form>

        <div class="or">
          <hr> <span>or</span>
        </div>
        <button type="button" (click)="createAccount()" class="btn-signup">CREATE ACCOUNT</button>

        <div class="description">If logging in for the first time, you will need to create a new account.
          <a href="https://intercom.help/vibesolutions/en/" target="_blank">Get help</a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #awsLogin>
    <div class="amplify-container">
      <amplify-authenticator
          [formFields]="formFields"
          [initialState]="initialState"
          [hideSignUp]="false"
          [signUpAttributes]="signUpAttributes"
          [services]="amplifyServices()"
      >

          <ng-template amplifySlot="header">
            <img src="assets/vibeiq_logo.jpg" class="logo">
          </ng-template>


          <ng-template amplifySlot="sign-in-header">
            <h3 class="amplify-heading"> Sign in </h3>
          </ng-template>
          <ng-template amplifySlot="sign-up-header">
            <h3 class="amplify-heading"> Create Account </h3>
          </ng-template>


          <ng-template amplifySlot="sign-in-footer">
            <div style="text-align: center; margin-top: 16px;">
              <a routerLink="" (click)="authenticator.toForgotPassword()">
                Forgot Password
              </a>
              <div style="margin-top: 100px;">
                First time signing in?
                <a routerLink="" (click)="authenticator.toSignUp()"> Create new account </a>
              </div>
            </div>
          </ng-template>
          <ng-template amplifySlot="sign-up-footer">
            <div style="text-align: center; margin-top: 16px;">
              Already have an account?
              <a routerLink="" (click)="createToSignIn()"> Sign In </a>
            </div>
          </ng-template>


          <ng-template
            amplifySlot="authenticated"
            let-user="user"
            let-signOut="signOut"
          ></ng-template>

      </amplify-authenticator>
    </div>
  </ng-template>


  <div class="right-section">
    <img src="assets/logo.svg">
  </div>
</div>
