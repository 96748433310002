import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const isSignedIn: boolean = this.authService.isSignedIn();
      console.debug("is signed in?", isSignedIn)
      if (!isSignedIn) {
        console.debug("guard: attempting access token refresh");
        if(await this.authService.refreshAccessToken()) {
          return true;
        }
        await this.authService.clearCognitoData();
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      }
      return isSignedIn;
    } catch (error) {
      console.log('error in auth guard.. routing to login: ', error);
      console.log(`should re-route to ${JSON.stringify(state.url)}`);

      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
