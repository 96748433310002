import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { I18n } from 'aws-amplify/utils';
import { signUp } from 'aws-amplify/auth';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  showCognitoLogin = false;

  initialState: 'signIn' | 'signUp' | 'forgotPassword' = 'signIn';
  signUpAttributes = ['username', 'password', 'confirm_password'];
  public formFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: 'Enter your email',
        isRequired: true,
        autocomplete: true
      },
      password: {
        labelHidden: true,
        placeholder: 'Enter your password',
        isRequired: true
      }
    },
    signUp: {
      username: {
        order: 1,
        placeholder: 'Email'
      },
      password: {
        order: 2
      },
      confirm_password: {
        order: 3
      }
    },
    forgotPassword: {
      username: {
        labelHidden: true,
        placeholder: 'Enter your email',
        isRequired: true
      },
    }
  };


  constructor(
    private authService: AuthService,
    public authenticator: AuthenticatorService,
    private route: ActivatedRoute
  ) {
    I18n.setLanguage('en');
    const dict = {
      en: {
        'Sign in': 'SUBMIT',
      }
    };
    I18n.putVocabularies(dict);
  }

  amplifyServices = () => {
    const authService = this.authService;
    return {
      async handleSignUp(formData: Record<string, any>) {
        let { username, password, attributes } = formData;
        username = username.toLowerCase();
        const domain = await authService.getSSORequest(username).toPromise()
        if (Boolean(domain)) {
          console.error("Signup email belongs to SSO domain. Sign in through SSO");
          throw new Error("This email address is managed through your company's single sign-on solution. Please use 'Sign In' option below")
        } else {
          try {
            return await signUp({
              username,
              password,
              options: {
                userAttributes: attributes,
                autoSignIn: true
              },
            });
          } catch (e) {
            console.error("caught error during cognito signup", e);
            throw e;
          }
        }
      },
    }
  };

  async ngOnInit() {
    if (this.authService.isSignedIn()) {
      const returnUrl = this.route?.snapshot?.queryParams.returnUrl || this.authService.getDefaultHomePage();
      console.log("user is logged in, redirecting to", returnUrl)
      document.location.href = returnUrl
    } else {
      // make sure we clear out any stale cognito data.
      // this happens when the user signs out but still has local cognito data.
      await this.authService.clearCognitoData();
    }
  }

  ngOnDestroy() {
    // return onAuthUIStateChange;     // NO NEED | we redirect to the app url
  }

  onEmailSubmit(f: NgForm) {
    const email = f?.value?.email;
    const returnUrl = this.route?.snapshot?.queryParams.returnUrl || this.authService.getDefaultHomePage();
    this.authService.getSSORequest(f?.value?.email).subscribe((data: any) => {
      if (Boolean(data)) {
        window.location.href = data.url + "?relayState=" + returnUrl;
      } else {
        this.showCognitoLogin = true;
        this.authenticator.signOut();     // Amplify Auth status : SignedIn

        setTimeout(() => {
          const elements: any = document.getElementsByClassName('amplify-input');
          elements[0].value = email;
          const pass: HTMLElement = elements[1];
          pass.setAttribute('id', 'amplify-password-field');
        }, 500);
      }
    });
  }

  createToSignIn() {
    this.showCognitoLogin = false;
    this.authenticator.signOut();
    this.authenticator.toSignIn();

  }

  createAccount() {
    this.showCognitoLogin = true;
    this.initialState = 'signUp';
    this.authenticator.toSignUp();
  }
}
