import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthUser } from 'aws-amplify/auth';
import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { defaultStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AuthGuard } from './auth.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

// Auth.configure({
//   region: environment.userPoolRegion,
//   userPoolId: environment.userPoolId,
//   userPoolWebClientId: environment.userPoolWebClientId,
//   mandatorySignIn: true,
//   oauth: {},
// });

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId: environment.userPoolId,
    userPoolClientId: environment.userPoolWebClientId
  }
};
Amplify.configure({
  Auth: authConfig
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

@NgModule({
  declarations: [LoginComponent],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AmplifyAuthenticatorModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    HttpClientModule,
    [CookieService],
  ],
  exports: []
})
export class AuthModule { }
